/**
 * Type: ページ
 * What: MLB café FUKUOKAページ
 */
import React, { useEffect } from 'react';

// Data Props Template
const Template = () => {
  // 初回DOM
  useEffect(() => {
    window.location.href = "/the-foodhall/mlbcafe-fukuoka";
  });
  return true;
};

export default Template;
